<template>
  <article v-if="hotel" class="hotel-card" @mouseover="handleHotelCardMouseOver(hotel)"
           @mouseleave="handleHotelCardMouseLeave(hotel)">
    <vueper-slides fixed-height="100%" :bullets="false" class="image">
      <vueper-slide
          v-for="(slide, i) in hotel.images"
          :key="i"
          :image="slide"
          :content="getEcoContent()"
      >
      </vueper-slide>
    </vueper-slides>
    <div class="basic-info" @click="routeToHotelDetail">
      <div class="stars">
        <img v-for="(star, i) in hotel.stars" class="star" :key="`stars-hotel-card-${hotel.id}-${i}`"
             src="../../assets/ic_hotel_card_star_1.svg" alt="">
      </div>
      <h2 v-bind:style="adaptFontSizeToHotelNameLength(hotel.name)">{{ hotel.name }}</h2>
      <p class="distances">
        <span class="distance-center">
          {{ hotel.km_to_center }} {{ $t('kmFromCenter') }}
        </span>
        <span class="distance-congress">
          {{ hotel.km_to_congress }} {{ $t('kmFromCongress') }}
        </span>
      </p>
    </div>

    <div class="reserve" @click="routeToHotelDetail">
      <div class="container">
        <div class="price">
          <div v-if="dataHotelsIsReady">
            <p>{{ $t('rateFrom') }}</p>
            <h3>{{ calculateHotelPrice() }}€</h3>
            <p>{{ $t('averagePriceNight') }}</p>
          </div>
        </div>
        <div class="reservation-info">
          <p>{{ $t('vatIncluded') }}</p>
          <p v-if="dataHotelsIsReady">{{ hotel.meal }}</p>
        </div>
        <p class="more-info" @click.stop="expandHotelCard">{{ $t('moreInfo') }}</p>
        <a v-if="hotel.booking_url" target="_blank" rel="noopener noreferrer" @click="routeToHotelDetail"
           class="button primary link-to-detail">{{ $t('moreInfo') }}</a>
        <a v-if="hotel.booking_url" target="_blank" rel="noopener noreferrer" :href="hotel.booking_url"
           class="button primary book-now">{{ $t('bookNow') }}</a>
        <div v-else class="btn-booknow-placeholder"></div>
      </div>

    </div>
  </article>
</template>

<script>
import {calculateHotelPriceHelper} from '../../helpers/calculateHotelPrice'
import {mapGetters} from 'vuex'
import {VueperSlides, VueperSlide} from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'HotelCard',
  components: {VueperSlides, VueperSlide},
  props: {
    hotel: Object,
    cityCenterLat: Number,
    cityCenterLng: Number,
    congressLat: Number,
    congressLng: Number,
    startDate: String,
    finishDate: String,
    includedRates: Array
  },

  data: () => {
    return {
      leftArrow: '',
      rightArrow: '',
      budgetId: '',
    }
  },
  methods: {
    adaptFontSizeToHotelNameLength(hotelName) {
      const hotelNameIsSmall = hotelName.length <= 18
      const hotelNameIsVeryBig = hotelName.length > 30
      const isMobile = window.screen.availWidth < 768

      if (hotelNameIsVeryBig && isMobile) {
        return {fontSize: '15px'}
      } else if (hotelNameIsSmall && !isMobile) {
        return {fontSize: '32px'}
      } else if (!hotelNameIsSmall && !isMobile) {
        return {fontSize: '26px'}
      } else if (hotelNameIsSmall && isMobile) {
        return {fontSize: '20px'}
      } else if (!hotelNameIsSmall && isMobile) {
        return {fontSize: '18px'}
      }
    },
    sanitizeUrl(url) {
      url = url.trim()
      if (!url.includes('http')) {
        return 'http://' + url
      }
      return url
    },
    calculateHotelPrice() {
      return calculateHotelPriceHelper(this.hotel)
    },
    isMealReady() {
      return (this.dataHotelsIsReady && this.hotel.meal)
    },
    expandHotelCard(evt) {
      // Using path to reach the parent article item to add expanded class
      const isCardExpanded = evt.path[3].classList.toggle('expanded')
      if (isCardExpanded) {
        evt.currentTarget.innerText = 'Less info'
      } else {
        evt.currentTarget.innerText = 'More info'
      }
    },
    handleHotelCardMouseOver(hotel) {
      this.$emit('hoveredHotel', hotel)
    },
    handleHotelCardMouseLeave() {
      this.$emit('hoveredHotel', null)
    },
    routeToHotelDetail(evt) {
      this.darkenCardBackground(evt)
      this.$router.push({
        name: 'hotel-detail-page',
        params: {
          id: this.hotel.id
        }
      })
    },
    darkenCardBackground(evt) {
      evt.currentTarget.parentNode.style.background = '#E4E4E4'
    },
    getEcoContent() {
      if(this.hotel.eco_friendly) {
        return '<div class=\'eco-indicator\'><i class=\'fa fa-leaf\'> </i> Eco-friendly</div>'
      }
      return ''
    }
  },
  computed: {
    ...mapGetters(['site', 'dataHotelsIsReady']),
  },
  mounted() {
    this.leftArrow = require('@/assets/list-page/ic_hotel_card_arrow_left.svg')
    this.rightArrow = require('@/assets/list-page/ic_hotel_card_arrow_right.svg')
    this.budgetId = process.env.VUE_APP_BUDGET_ID
  }
}
</script>

<style lang="scss" scoped>

@import "@/scss/color-mixin.scss";

.hotel-card {
  height: 280px;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 70% 30%;
  grid-template-areas:
    "image image basic-info basic-info reserve"
    "image image availability availability reserve";
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  text-align: left;
  position: relative;

  .image {
    grid-area: image;

    img {
      border-radius: 5px 0 0 5px;
      width: 100%;
      height: 100%;
    }
  }

  .hotel-card-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
  }

  .basic-info {
    grid-area: basic-info;
    margin: 10px 26px 0 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    .star {
      margin-left: 3px;
    }

    h2 {
      font-size: 32px;
      color: $secondary-black;
      font-family: 'Frank Ruhl Libre', sans-serif;
      margin: 5px 0;
    }

    p {
      display: flex;

      span.distance-center {
        border-right: 1px solid #B6B6B6;
        padding-right: 10px;
        margin-right: 10px;
      }
    }

  }

  .ammenities {
    grid-area: ammenities;
    margin: 20px 20px;
    display: flex;
    flex-wrap: wrap;

    p {
      width: 50%;
      font-size: 13px;

      .label-walking {
        padding-right: 5px;
      }
    }
  }

  .availability {
    grid-area: availability;
    align-self: center;
    margin-left: 25px;
    display: flex;
    align-items: center;

    p {
      text-decoration: underline;
      margin-bottom: 8px;
      display: flex;
      font-size: 16px;

      &::before {
        margin-right: 6px;
        content: url('../../assets/ic_card_room_black.svg');
      }
    }

    .progress-bar {
      height: 7px;
      background-color: #E9E9E9;
      border-radius: 2.75px;
      max-width: 135px;

      div {
        height: 7px;
        background-color: $secondary-black;
        border: 0.5px solid #788EA9;
        border-radius: 2.75px;
        width: 80%;
      }
    }
  }

  .distances {
    &:before {
      content: var(--icon-walking-distance);
    }
  }


  .reserve {
    grid-area: reserve;
    padding: 0 18px;
    border-left: 1px solid #E9E9E9;
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .container {
      height: 100%;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 40% 32% 14% 14%;
      grid-template-areas:
      "price"
      "reservationInfo"
      "linkToDetail"
      "bookNow";

      p {
        font-size: 11px;
        font-weight: 600;
      }

      p.more-info {
        grid-area: moreInfo;
        display: none;
      }

      .price {
        grid-area: price;
        align-self: end;

        h3 {
          font-family: 'Source Sans Pro', sans-serif;
          margin: 0;
          font-size: 30px;
        }

        p {
          margin: 5px 0;
        }

        p:last-child {
          text-transform: uppercase;
        }
      }

      .reservation-info {
        grid-area: reservationInfo;

        p:before {
          margin-right: 5px;
          vertical-align: sub;
          content: var(--icon-circular-check);
        }

      }

      .link-to-detail a {
        grid-area: linkToDetail
      }

      .book-now a {
        grid-area: bookNow;
      }

    }

    // Style to be hoisted to the parent/ancestor component
    .button {
      height: 28px;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      line-height: 28px;
      border-radius: 5px;
      // Commented, was causing book now button to appear on top of overlay
      //z-index: 3;
    }

    .primary {
      // background-color: #7C8232;
      background-color: var(--primary-color);
      color: #fff;
    }

    .primary:hover {
      cursor: pointer;
    }
  }

  .btn-booknow-placeholder {
    height: 40px;
    line-height: 40px;
    text-decoration: none;
    margin-top: 60px;
  }

  section {
    margin: 0;
  }
}

@media (max-width: 768px) {

  .hotel-card {
    min-height: 375px;
    height: 460px;
    grid-template-columns: 100%;
    grid-template-rows: 40% 30% 30%;
    grid-template-areas:
    "image"
    "basic-info"
    "reserve";

    &.expanded {
      min-height: 475px;
      grid-template-rows: 35% 25% 25% 15%;
      grid-template-areas:
      "image"
      "basic-info"
      "reserve"
      "availability";

      .availability {
        display: initial;
        margin: 0 15px;
        padding-top: 15px;
      }

      .reserve {
        border-bottom: 1px solid #E9E9E9;

        p.more-info {
          &::after {
            content: url('../../assets/filters-icons/ic_button_deactive_dropdown_active.svg');
          }
        }
      }
    }

    .image {
      img {
        border-radius: 5px 5px 0 0;
        height: 150px;
      }
    }

    .basic-info {
      
      margin: 20px 15px 0;
      padding-bottom: 10px;

      p {
        font-size: 13px;
      }
    }

    .availability {
      display: none;
    }

    .reserve {
      padding: 10px 0 0 0;
      margin: 0 15px;
      border: none;
      text-align: left;
      justify-content: initial;

      .container {
        margin: 0;
        grid-template-columns: 50% 50%;
        grid-template-rows: 55% 45%;
        grid-template-areas:
        "price reservationInfo"
        "linkToDetail bookNow";

        .price {

          h3 {
            line-height: 25px;
          }

          p {
            margin: 0;

            &:last-child {
              text-transform: none;
              font-size: 14px;
              font-weight: normal;
            }
          }
        }

        .reservation-info {

          p {
            font-size: 14px;
            font-weight: normal;
          }
        }

        p.more-info {
          display: none;
          font-size: 14px;
          font-weight: bold;
          align-self: flex-end;
          padding-bottom: 10px;

          &:active {
            text-decoration: underline;
          }

          &::after {
            content: url('../../assets/filters-icons/ic_button_deactive_dropdown_active_down.svg');
            padding-left: 5px;
          }
        }

        .button {
          width: 80%;
          margin-top: 10px;
          justify-self: center;
        }

      }

    }

  }
}

@media (max-width: 900px) and (min-width: 769px)  {
  .hotel-card{
    .reserve{
      .button{
        font-size: 11px;
      }
    }
  }
}

</style>
