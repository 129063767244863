<template>
  <div>
    <navbar :class="{ show: true }" :showLinks="false"></navbar>
    <div v-if="booking" class="flex-container">
      <div class="topics">
      </div>
      <div class="main-text">
        <h3>{{ $t('oops') }}</h3>


        <p v-if="booking.is_refunded">{{ $t('bookingNotSuccessfulRefunded') }}</p>
        <p v-else-if="booking.is_refund_error"> {{ $t('bookingNotSuccessfulNotRefunded') }}</p>
        <p v-else> {{ $t('bookingNotSuccessfulCheckPaymentError') }}</p>
        <br>
        <p v-if="booking.is_refunded">{{ $t('aCopyIsBeingSentRefunded') }}</p>
        <p v-else-if="booking.is_refund_error"> {{ $t('aCopyIsBeingSentNotRefunded') }}</p>
        <p v-else>{{ $t('aCopyIsBeingSentCheckPaymentError') }}</p>
        
        <h2>{{ $t('bookingDetails') }}</h2>

        <div class="payment-info-content" style="max-width: 600px">
          <div class="row">
            <p class="column">{{ $t('hotelName') }}</p>
            <p class="column">{{booking.hotel.name}}</p>
          </div>
          <div class="row">
            <p class="column">{{ $t('arrivalDate') }}</p>
            <p class="column">{{this.formatDate(booking.check_in)}}</p>
          </div>
          <div class="row">
            <p class="column">{{ $t('departureDate') }}</p>
            <p class="column">{{this.formatDate(booking.check_out)}}</p>
          </div>
          <div class="row">
            <p class="column">{{ $t('roomType') }}</p>
            <p class="column">{{booking.hotel_room}}</p>
          </div>
          <div class="row">
            <p class="column">{{ $t('amountCharged') }}</p>
            <p v-if="booking.payment_amount" class="column">{{booking.payment_amount}} {{booking.currency}}</p>
            <p v-else class="column">{{booking.price}} EUR</p>
          </div>
          <div class="row">
            <p class="column">{{ $t('paymentID') }}</p>
            <p class="column">{{booking.payment_reference}}</p>
          </div>
        </div>
        <div class="links">
          <a @click="goToHotel" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
            {{ $t('goHotel') }}
          </a>
          <a @click="goToCongress" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
            {{ $t('goCongress') }}
          </a>
        </div>
          <br>
          <br>
      </div>
    </div>
    <contact-footer :showFooter="true"/>
    <legal-footer :showFooter="true"/>
  </div>
</template>

<script>

import Navbar from '@/components/Navbar'
import ContactFooter from '@/components/landing-page/ContactFooter'
import LegalFooter from '@/components/landing-page/LegalFooter'
import bookingService from "@/services/bookingService";
import { formatDate } from '@/helpers/utils'

export default {
  name: "Error",
    components: {
    Navbar,
    ContactFooter,
    LegalFooter,
  },
  data() {
    return {
      reference: null,
      booking: null,
    }
  },
  methods: {
    formatDate: formatDate,
    goToCongress() {
    this.$router.push({name: 'home'})
    },
    goToHotel() {
    this.$router.push({name: 'hotel-detail-page', params: {id: this.booking.hotel.id}})
    }
  },
  mounted() {
    this.reference = this.$route.params.reference
    bookingService.detail(this.reference)
        .then(data => {
          if (data.is_booked)
            this.$router.push({name: 'booking-detail', params: {reference: this.reference}})

          this.booking = data
        })
        .catch(() => this.$router.push('/404'))
  }
}
</script>


<style lang="scss" scoped>
.links {
  margin-top: 30px;
  margin-left:85px;
  margin-right:20px;
}

.text-space {
  margin-top: 25px;
}
.payment-info-content {
  background: rgba(233, 233, 233, 0.3);
  padding: 10px 10px 10px 10px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}
.column {
  flex: 1 0 50%;
  white-space: nowrap;
  text-align: left;
  box-sizing: border-box;
}
.flex-container {
  display: flex;
  justify-content: start;
  align-items: top;
  height: auto;
  width: auto;
  padding-top: 30px;
  text-align: left;

}

.footer-container {
  margin: 0;

}

.main-text {
  width: 50%;
  font-size: 18px;

  h3 {
    font-size: 42px;
  }
}

.topics {
  padding-left: 50px;
  width: 30%;
}

a.btn.btn-primary {
  line-height: 40px;
  margin-left: 20px;
  width: 170px;
  height: 40px;
  color: white;
  display: inline-block;
  background-color: var(--primary-color);
  margin-bottom: 25px;

  &:hover {
    background-color: var(--hover-color);
  }
}

@media (max-width: 768px) {
  .links {
    padding-top: 60px;
    padding-left: 0 !important;
    margin-left:100px;
    margin-right:20px;
    overflow: scroll;

    a {
      font-weight: bold;
      color: #747272;
      font-size: 14px !important;
    }
  }

  .flex-container {
    height: auto;
    width: auto;
    padding-top: 60px;
    margin: 0 20px;
    flex-direction: column;

    .topics {
      padding-left: 0;
    }

    .main-text {
      width: 100%;

      h3 {
        font-size: 22px;
      }

      p, ul {
        font-size: 15px;
      }
    }
  }

}

</style>