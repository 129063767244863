import { createI18n } from 'vue-i18n'

// {{ $t('') }} for template translation
// this.$i18n.t('') for in code translation
// Use tc instead of t when you use translations multiples like
// adult: 'ADULT | ADULTS', and the position of the selected translation
// {{ $tc('adult', 1) }} || this.$i18n.tc('adult', 2)
// data: function() {}, in vue files when translation is included inside data
const messages = {
  en: {
    nearVenue: 'Near the venue',
    ecoFriendly: 'Eco-friendly',
    cityCenter: 'City center',
    nearVenueHotels: 'hotels found near the venue',
    ecoFriendlyHotels: 'eco-friendly hotels found',
    aroundCityCenterHotels: 'hotels found around the city center',
    see: 'See',
    options: 'options',
    searchAll: 'See all hotels',
    hotels: 'hotels',
    scrollDown: 'Scroll down to see most popular hotels',
    officialHousing: 'Official Housing Agency',
    seeAllHotels: 'See all hotels',
    kmToCongress: 'km to Congress venue',
    cookiesPolicy: 'Cookies Policy',
    cancellationPolicy: 'Cancellation Policy | Cancellation Policies',
    noResults: 'No results found',
    restoreFilters: 'Restore filters to find your perfect hotel',
    showMore: 'Show more hotels',
    stars: 'Category',
    apply: 'Apply',
    restore: 'Restore',
    selectCategory:'Select category',
    price: 'Price',
    pricePerNight: 'Price per night',
    taxesAndFees: 'Taxes and fees included',
    location: 'Location',
    maximumDistance: 'Maximum distance',
    congress: 'Congress',
    downtown: 'Downtown',
    upTo: 'Up to',
    hotelName: 'Hotel name',
    date: 'Date',
    filters: 'Filters',
    sortBy: 'Sort By',
    distanceToCongress: 'Distance to venue',
    distanceToCity: 'Distance to city center',
    priceLowest: 'Price(lowest first)',
    priceHighest: 'Price(highest first)',
    starsLowest: 'Category (lowest first)',
    starsHighest: 'Category (highest first)',
    hotelsFound: 'hotels found',
    followUs: 'Follow us',
    rateFrom: 'Rate from:',
    averagePriceNight: 'Average price per night',
    vatIncluded: 'VAT included',
    moreInfo: 'More info',
    bookNow: 'book now',
    kmFromCenter: 'km from center',
    kmFromCongress: 'km from congress',
    goBack: 'Go back',
    roomInformation: 'Room information',
    shareFacebook: 'Share on Facebook',
    shareTwitter: 'Share on Twitter',
    shareLinkedIn: 'Share on LinkedIn',
    shareWhatsapp: 'Share on Whatsapp',
    shareEmail: 'Share by email',
    description: 'Description',
    chooseRoom: 'Choose Room',
    amenities: 'Amenities',
    similarHotels: 'Similar Hotels',
    bestDeal: 'Best deal',
    generalConditions: 'General conditions',
    continue: 'Continue',
    getThere: 'How to get there?',
    showAll: 'Show all',
    similarHotelsMatch: 'Similar hotels matching your criteria',
    search: 'Search',
    chooseYourRoom: 'Choose your room',
    option: 'Option',
    nonRefundable: 'Non refundable',
    room: 'Room | Rooms',
    roomEs: 'room/s',
    nightsStay: 'nights stay',
    totalPrice: 'Total price',
    oops: 'Oops, something went wrong!',
    paymentCancelled: 'Payment has been cancelled',
    paymentNotCompleted: 'The payment has not been completed successfully so the booking has not been made.',
    goHotel: 'Go to hotel',
    goCongress: 'Go to congress',
    404: '404 - PAGE NOT FOUND!',
    notFound1: 'Sorry but the page you are looking for does not exist, have been removed,',
    notFound2: 'name changed or its temporarily unavailable',
    bookingConfirmed: 'Booking confirmed',
    bookingConfirmedText: 'Your booking has been confirmed. You will receive an email with information about reservation',
    guestId: 'Confirmation booking number/Guest ID:',
    guestName: 'Guest First Name:',
    guestSurname: 'Guest Last Name:',
    country: 'Country:',
    pleasure: 'It is a pleasure to confirm your hotel booking for the',
    held: 'to be held in',
    from: 'from | From',
    accommodationBooking: 'Accommodation Booking',
    hotel: 'Hotel',
    address: 'Address',
    numberRooms: 'Number Of Rooms',
    adult: 'ADULT | ADULTS',
    boardType: 'Board Type',
    checkInDate: 'Check-In Date',
    checkOutDate: 'Check-Out Date',
    paymentDetails: 'Payment Details',
    receiptNumber: 'Receipt Number',
    dateOfPayment: 'Date of Payment',
    currency: 'Currency',
    paymentDescription: 'Payment Description',
    card: 'Card',
    paymenttext: 'As you have paid in advance all your stay with the option Full Payment, no payment will be necessary at the hotel upon check-in or check-out, except extras.',
    modificationsAndCancellations: 'Modifications and cancellations',
    modificationsText: 'According to the hotel in which you have made your reservation, cancellation policies are as follows:',
    to: 'to',
    modificationsEmail: 'For cancellations or modifications, please send an email to',
    refundAfterMeeting: 'Any refund will be made after the meeting. Please note that credit card companies do not return fees for reimbursement.',
    invoiceInformation: 'Invoice information',
    invoiceEmail: 'You will receive the payment invoice, or credit note invoice, in another email from Bco Congresos.',
    freeContact: 'Feel free to contact us for any further information you may need.',
    lookForward: 'We look forward to seeing you in',
    bookingInformation: 'Booking information',
    reservationDates: 'Reservation dates:',
    congressObservations: 'Congress observations',
    roomLabel: 'Room',
    privacyPolicy: 'Privacy Policy',
    beforeSend: 'Before sending this form, please read the',
    dataProtection: 'BASIC INFORMATION ON DATA PROTECTION',
    continuingProcess: 'By continuing with the process, you declare to have read this information.',
    payment: 'Payment',
    contactDetails: 'Guest details',
    nameReq: '*Name',
    lastNameReq: '*Last Name',
    name: 'Name',
    lastName: 'Last Name',
    organization: 'Organization',
    city: 'City',
    postcode: 'Postcode',
    countryReq: '*Country',
    phoneNumber: '*Phone Number',
    emailAddress: '*Email Address',
    verifyEmail: '*Verify Email Address',
    emailText: 'Please enter the email address where you want to receive all the booking information.',
    billingInformation: 'Billing information',
    billingText: 'An invoice will be generated in the booking process.',
    billingText2: 'Please complete the following fields with your company / organization /personal billing details (who is making the payment).',
    billingText3: '*Billing Organization is the name of your company/organisation, or your personal name if you want the invoice in your name.',
    billingText4: 'The billing tax number is the company tax identification number (eg. VAT, RN or company identification equivalent), or your personal identification number (ID card, passport, social security etc.)',
    billingOrganization: '*Billing Organization',
    billingTaxNumber: '*Billing Tax Number',
    billingAddresss: '*Billing Address',
    billingCity: '*Billing City',
    billingPostcode: '*Billing Postcode',
    billingCountry: '*Billing country',
    customerObservations: 'Customer Observations',
    customerObservationsText: 'Please include any comments to be passed on to the hotel.',
    countryPrefix: 'Country Prefixes',
    forCancellationsBetween: 'For cancellations between',
    forCancellationsOn: 'For cancellations on',
    and: 'and',
    beACharge: 'there will be a charge of',
    euros: 'Euros',
    freeCancellationsUntil: 'Free cancellation until',
    for: 'for',
    guest: 'Guest | Guests',
    addRoom: 'Add room',
    fromLabel: 'From:',
    toLabel: 'to:',
    show: 'show more',
    errorsForm: 'There are errors in the form.',
    validPhoneSpain: 'Please enter a valid phone number for Spain.',
    validSpainId: 'Enter a DNI, NIE or CIF valid for Spain.',
    advertisement: 'Advertisement',
    priceChanged: 'The price shown in the room selection has changed.',
    paymentPlarformNotWorking: 'The payment platform is not working as expected, please try again later.',
    paymentPlarformNotAvailable: 'The payment platform is not available, please try again later.',
    valueRequired: 'Value is required',
    notValidEmail: 'Value is not a valid email address',
    valueMustBeEqual: 'The email does not match',
    valueTooLong: 'Value too long',
    timeLeftBooking: 'Time left for your booking to expire',
    changeDates: 'Change dates.',
    numberOfNights: 'Number of nights',
    clickForMap: 'Click for map',
    observations: 'Observations',
    bookingDetails: 'Booking Details:',
    aCopyIsBeingSentNotRefunded: 'A copy of this message is being sent to your email and our team will get in contact with you shortly in order to rebook your room.',
    aCopyIsBeingSentRefunded: 'A copy of this message is being sent to your email and our team will get in contact with you shortly in order to rebook your room.',
    aCopyIsBeingSentCheckPaymentError: "A copy of this message is being sent to your email and our team will get in contact with you shortly in order to rebook your room.",
    bookingNotSuccessfulNotRefunded: "Your booking could not be completed and we've tried to return your money but the credit card transaction failed.",
    bookingNotSuccessfulRefunded: "Your booking could not be completed but we want to confirm that the payment you made has been automatically refunded.",
    bookingNotSuccessfulCheckPaymentError: "Your booking could not be completed because the payment could not be verified.",
    arrivalDate: 'Arrival Date',
    departureDate: 'Departure Date',
    roomType: 'Room Type',
    amountCharged: 'Amount Charged',
    paymentID: 'Payment ID',
    searchCriteria: 'The search criteria have changed, click the search button to update the results.',
    unableAvailability: 'Unable to get availability, please try again later.',
    offerCouldNotLoaded: 'This offer could not be booked, please try again later.',
    advantagesBookingUs: 'Advantages of booking with us',
    onSiteSupport: 'On site support during the congress',
    ratesCompetitive: 'Competitive Rates',
    expertiseAndExperience: 'Expertise and experience',
    errorFilteringHotelDate: 'There was a problem filtering hotels, please try again later',
    popular: 'Popular',
    flexRate: 'Flexible rate',
    nonRefundableChooseRoomText: 'This is a non refundable booking and cancellations or modifications are not permitted.',
  },
  es: {
    nearVenue: 'Cerca de la sede del congreso',
    ecoFriendly: 'Eco-friendly',
    cityCenter: 'Centro ciudad',
    nearVenueHotels: 'hoteles encontrados cerca de la sede',
    ecoFriendlyHotels: 'hoteles eco-friendly encontrados',
    aroundCityCenterHotels: 'hoteles encontrados en el centro de la ciudad',
    see: 'Ver',
    options: 'opciones',
    searchAll: 'Ver hoteles',
    hotels: 'hoteles',
    scrollDown: 'Desliza hacia abajo para ver los hoteles más populares',
    officialHousing: 'Agencia oficial de alojamiento',
    seeAllHotels: 'Ver todos los hoteles',
    kmToCongress: 'km de la sede del congreso',
    cookiesPolicy: 'Política de cookies',
    cancellationPolicy: 'Política de cancelación | Políticas de cancelación',
    noResults: 'No se encontraron resultados',
    restoreFilters: 'Restaura los filtros para encontrar tu hotel perfecto',
    showMore: 'Ver más hoteles',
    stars: 'Categoría',
    apply: 'Aplicar',
    restore: 'Restaurar',
    selectCategory:'Seleccionar categoría',
    price: 'Precio',
    pricePerNight: 'Precio por noche',
    taxesAndFees: 'Tasa y comisiones ya incluidas',
    location: 'Localización',
    maximumDistance: 'Distancia máxima',
    congress: 'Congreso',
    downtown: 'Centro',
    upTo: 'Hasta',
    hotelName: 'Nombre del hotel',
    date: 'Fecha',
    filters: 'Filtros',
    sortBy: 'Filtrar por',
    distanceToCongress: 'Distancia de la sede del congreso',
    distanceToCity: 'Distancia del centro de la ciudad',
    priceLowest: 'Precio (de menor a mayor)',
    priceHighest: 'Precio (de mayor a menor)',
    starsLowest: 'Categoría (de menor a mayor)',
    starsHighest: 'Categoría (de mayor a menor)',
    hotelsFound: 'hoteles encontrados',
    followUs: 'Síguenos',
    rateFrom: 'Precio desde:',
    averagePriceNight: 'Precio medio por noche',
    vatIncluded: 'IVA  incluido',
    moreInfo: 'Más info',
    bookNow: 'Reservar',
    kmFromCenter: 'km del centro de la ciudad',
    kmFromCongress: 'km de la sede',
    goBack: 'Volver',
    roomInformation: 'Información de la habitación',
    shareFacebook: 'Compartir en Facebook',
    shareTwitter: 'Compartir en Twitter',
    shareLinkedIn: 'Compartir en LinkedIn',
    shareWhatsapp: 'Compartir en Whatsapp',
    shareEmail: 'Compartir por email',
    description: 'Descripción',
    chooseRoom: 'Escoger habitación',
    amenities: 'Servicios del hotel',
    similarHotels: 'Hoteles similares',
    bestDeal: 'Mejor oferta',
    generalConditions: 'Condiciones generales',
    continue: 'Continuar',
    getThere: '¿Cómo llegar?',
    showAll: 'Mostrar todos',
    similarHotelsMatch: 'Hoteles similares que coinciden con sus criterios',
    search: 'Buscar',
    chooseYourRoom: 'Escoge tu habitación',
    option: 'Opción',
    nonRefundable: 'No reembolsable',
    room: 'Habitación | Habitaciones',
    roomEs: 'habitación/es',
    nightsStay: 'noches de estancia',
    totalPrice: 'Precio total',
    oops: 'Oops, algo salió mal!',
    paymentCancelled: 'El pago ha sido cancelado',
    paymentNotCompleted: 'El pago no se ha completado con éxito por lo que no se ha realizado la reserva.',
    goHotel: 'Ir al hotel',
    goCongress: 'Ir al congreso',
    404: '404 - PÁGINA NO ENCONTRADA!',
    notFound1: 'Lo sentimos pero la página que busca no existe, ha sido eliminada,',
    notFound2: 'el nombre cambió o no está disponible temporalmente',
    bookingConfirmed: 'Reserva confirmada',
    bookingConfirmedText: 'Su reserva ha sido confirmada. Recibirá un email con información sobre la reserva',
    guestId: 'Confirmación de reserva número/huésped ID:',
    guestName: 'Nombre del huésped:',
    guestSurname: 'Apellido del huésped:',
    country: 'País:',
    pleasure: 'Es un placer confirmar su reserva de hotel para',
    held: 'que se celebra en',
    from: 'desde | Desde',
    accommodationBooking: 'Reserva de alojamiento',
    hotel: 'Hotel',
    address: 'Dirección',
    numberRooms: 'Número de habitaciones',
    adult: 'ADULTO | ADULTOS',
    boardType: 'Tipo de régimen',
    checkInDate: 'Fecha de entrada',
    checkOutDate: 'Fecha de salida',
    paymentDetails: 'Detalles del pago',
    receiptNumber: 'Número de recibo',
    dateOfPayment: 'Fecha del pago',
    currency: 'Divisa',
    paymentDescription: 'Descripción del pago',
    card: 'Tarjeta',
    paymenttext: 'Como ha pagado por adelantado toda su estancia con la opción Pago Completo, no será necesario realizar ningún pago en el hotel al realizar la entrada o la salida, excepto extras.',
    modificationsAndCancellations: 'Modificaciones y cancelaciones',
    modificationsText: 'Las políticas de cancelación del hotel en el que has realizado tu reserva son las siguientes:',
    to: 'hasta',
    modificationsEmail: 'Para cancelaciones o modificaciones, por favor envíe un correo electrónico a',
    refundAfterMeeting: 'Cualquier reembolso se hará después de la fecha de reserva. Tenga en cuenta que las compañías de tarjetas de crédito no reembolsan las tarifas.',
    invoiceInformation: 'Información de la factura',
    invoiceEmail: 'Recibirá la factura de pago, o factura de crédito, en otro correo electrónico de Bco Congresos.',
    freeContact: 'No dude en ponerse en contacto con nosotros para cualquier información adicional que pueda necesitar.',
    lookForward: 'Esperamos verte en',
    bookingInformation: 'Información de la reserva',
    reservationDates: 'Fechas de reserva:',
    congressObservations: 'Detalles del congreso',
    roomLabel: 'Habitación',
    privacyPolicy: 'Política de privacidad',
    beforeSend: 'Antes de enviar este formulario, lea la',
    dataProtection: 'INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS',
    continuingProcess: 'Al continuar con el proceso, declaras haber leído esta información.',
    payment: 'Pago',
    contactDetails: 'Detalles del huésped',
    nameReq: '*Nombre',
    lastNameReq: '*Apellidos',
    name: 'Nombre',
    lastName: 'Apellidos',
    organization: 'Organización',
    city: 'Ciudad',
    postcode: 'Código Postal',
    countryReq: '*País',
    phoneNumber: '*Teléfono',
    emailAddress: '*Correo electrónico',
    verifyEmail: '*Verifique su correo electrónico',
    emailText: 'Por favor, introduzca la dirección de correo electrónico donde desea recibir toda la información de la reserva.',
    billingInformation: 'Datos de facturación',
    billingText: 'Se generará una factura en el proceso de reserva.',
    billingText2: 'Complete los siguientes campos con los detalles de facturación de su empresa/organización/personal (quién realiza el pago).',
    billingText3: '*Organización de facturación es el nombre de su empresa/organización, o su nombre personal si desea que la factura esté a su nombre.',
    billingText4: 'El Número de identificación fiscal de facturación es el número de identificación fiscal de la empresa (por ejemplo, IVA, RN o equivalente de identificación de la empresa), o su número de identificación personal (DNI, pasaporte, seguro social, etc.)',
    billingOrganization: '*Organización de la facturación',
    billingTaxNumber: '*Número de identificación fiscal',
    billingAddresss: '*Dirección de facturación',
    billingCity: '*Ciudad de facturación',
    billingPostcode: '*Código postal de facturación',
    billingCountry: '*País de facturación',
    customerObservations: 'Observaciones del cliente',
    customerObservationsText: 'Incluya cualquier comentario para transmitirlo al hotel.',
    countryPrefix: 'Prefijos de Países',
    forCancellationsBetween: 'Para las cancelaciones entre el',
    forCancellationsOn: 'Para las cancelaciones el',
    and: 'y el',
    beACharge: 'habrá un cargo de',
    euros: 'Euros',
    freeCancellationsUntil: 'Cancelación gratis hasta',
    for: 'para',
    guest: 'Huésped | Huéspedes',
    addRoom: 'Añadir habitación',
    fromLabel: 'Desde:',
    toLabel: 'hasta:',
    show: 'ver más',
    errorsForm: 'Hay errores en el formulario.',
    validPhoneSpain: 'Por favor introduce un número de telefono válido para España.',
    validSpainId: 'Introduce un DNI, NIE o CIF válido para España.',
    advertisement: 'Advertencia',
    priceChanged: 'El precio mostrado en la selección de habitación ha cambiado.',
    paymentPlarformNotWorking: 'La plataforma de pago no esta funcionado como se espera, por favor pruebe otra vez más tarde.',
    paymentPlarformNotAvailable: 'La plataforma de pago no esta disponible, por favor pruebe otra vez más tarde.',
    valueRequired: 'Se requiere el valor',
    notValidEmail: 'El valor no es un correo electrónico válido',
    valueMustBeEqual: 'El correo electrónico no coincide',
    valueTooLong: 'Valor demasiado largo',
    timeLeftBooking: 'Tiempo restante para que caduque su reserva',
    changeDates: 'Cambiar fechas.',
    numberOfNights: 'Número de noches',
    clickForMap: 'Haga clic aquí para ver el mapa',
    observations: 'Observaciones',
    bookingDetails: 'Detalles de la reserva:',
    aCopyIsBeingSentNotRefunded: 'Se le va a enviar una copia de este mensaje a su dirección de email y nuestro equipo se pondrá en contacto con usted lo antes posible para reservar su habitación.',
    aCopyIsBeingSentRefunded: 'Se le va a enviar una copia de este mensaje a su dirección de email y nuestro equipo se pondrá en contacto con usted lo antes posible para reservar su habitación.',
    aCopyIsBeingSentCheckPaymentError: "Se le va a enviar una copia de este mensaje a su dirección de email y nuestro equipo se pondrá en contacto con usted lo antes posible para reservar su habitación.",
    bookingNotSuccessfulNotRefunded: "Su reserva no ha podido completarse correctamente y hemos intentado devolverle el importe pero la transacción de tarjeta de crédito ha fallado.",
    bookingNotSuccessfulRefunded: "Su reserva no ha podido completarse correctamente, pero queremos confirmarle que el pago realizado ha sido devuelto automáticamente.",
    bookingNotSuccessfulCheckPaymentError: "Su reserva no ha podido completarse correctamente ya que no se ha podido verificar el pago.",
    arrivalDate: 'Fecha de llegada',
    departureDate: 'Fecha de salida',
    roomType: 'Tipo de habitación',
    amountCharged: 'Importe cobrado',
    paymentID: 'ID del pago',
    searchCriteria: 'Los criterios de búsqueda han cambiado, clique el botón de búsqueda para actualizar los resultados',
    unableAvailability: 'No se pudo obtener la disponibilidad, inténtelo de nuevo más tarde.',
    offerCouldNotLoaded: 'No se pudo reservar esta oferta, inténtalo de nuevo más tarde.',
    advantagesBookingUs: 'Ventajas al reservar con nosotros',
    onSiteSupport: 'Soporte durante el congreso',
    ratesCompetitive: 'Tarifas competitivas',
    expertiseAndExperience: 'Conocimiento y experiencia',
    errorFilteringHotelDate: 'Hubo un problema al filtrar hoteles, por favor pruebe otra vez más tarde',
    popular: 'Popular',
    flexRate: 'Tarifa flexible',
    nonRefundableChooseRoomText: 'Esta es una tarifa no reembolsable por lo que no se permiten ni cancelaciones ni modificaciones.',
  }
}

const i18n = createI18n({
  messages,
  locale: 'en'
})

export default i18n
