<template>
  <div id="app" v-if="dataIsReady">
    <h2 v-if="siteIsActive!= null && !siteIsActive">This website is now offline. Contact congresos@bcocongresos.com for assistance</h2>
    <template v-else>
      <notifications position="top center"/>
      <title-setter :title="congressName"/>
      <router-view/>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TitleSetter from "@/components/TitleSetter";

export default {
  components: {
    TitleSetter,
  },
  created() {
    this.$store.dispatch("fetchData").then(() => this.init_settings())
  },
  computed: {
    ...mapGetters(["dataIsReady", "congressName", "site", "siteIsActive"]),
  },
  methods: {
    init_settings() {
      if (!this.siteIsActive)
        return

      // Add google analytics settings to head
      if (this.site.google_analytics_id) {
        let script_import = document.createElement("script");
        script_import.src = `https://www.googletagmanager.com/gtag/js?id=${this.site.google_analytics_id}`;
        document.getElementsByTagName("head")[0].appendChild(script_import);

        let script_trace = document.createElement("script");
        script_trace.text =
            `window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${this.site.google_analytics_id}');`;

        document.getElementsByTagName("head")[0].appendChild(script_trace);
      }

      // Set language from site
      this.$root.$i18n.locale = this.site.language.toLowerCase()

      privacy.reload(privacyConfig[this.site.language])

      let root = document.documentElement;
      root.style.setProperty('--hero-image', "url(" + this.site.hero_image + ")")
      root.style.setProperty('--primary-color', this.site.primary_color)
      root.style.setProperty('--hover-color', this.site.hover_color)
      root.style.setProperty('--icon-circular-check', "url(" + this.site.icon_circular_check + ")")
      root.style.setProperty('--icon-walking-distance', "url(" + this.site.icon_walking_distance + ")")

      if (this.site.favicon) {
        const link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = this.site.favicon;
        document.getElementsByTagName("head")[0].appendChild(link);
      }
    },
  },
};
</script>

<style lang="scss">
// Privacy banner
#privacyContainer {
  width:100% !important;
  .tabCk-wrap {
    border-radius:0;
  }
  .tabCk + label {
    border-radius:0;
  }
  .primaryBt {
    background:var(--primary-color);
  }
}


@media screen and (max-device-width: 1200px) {
  html,
  body {
    font-size: 12px;
  }
}

/* layout */

section {
  margin: 10px 20px;
}

.filter-trigger,
.bp-dropdown__btn {
  border: none;
  color: $primary-black;
  background: lightgray;
  font-weight: bold;
  border-radius: 5px;
  width: 187px;
  height: 45px;
  padding: 15px 20px 15px 50px !important;
  font-size: 14px;
  cursor: pointer;
}

.filter-trigger::placeholder {
  color: $primary-black;
  font-weight: bold;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
}

.bp-dropdown__btn {
  display: flex;
  justify-content: space-between;
}

.bp-dropdown__body {
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;

  p {
    margin-bottom: 25px;
  }
}

.is-big-bp__body {
  min-width: 320px !important;
  text-align: left;
}

.hotel-name-bp__body {
  max-height: 550px;
  //min-height: 200px;
  width: 250px;
  padding: 0;
}

.is-scrollable-bp__body {
  padding: 0;
  height: 200px;
  overflow: scroll;
}

.asd__wrapper,
.asd__wrapper--datepicker-open {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-top: 10px;
  border: none !important;
}

.asd__keyboard-shortcuts-trigger {
  display: none;
}

.asd__day--enabled {
  border: none !important;
}

.asd__day,
.asd__day--enabled,
.asd__day--disabled {
  width: 20px !important;
  height: 20px !important;
}

.asd__selected-date-one {
  border-radius: 5px 0 0 5px !important;
}

.asd__selected-date-two {
  border-radius: 0 5px 5px 0 !important;
}

.asd__month-table {
  border-top: 1px solid #d8d8d8;
  margin-top: 40px !important;
}

.asd__day-button {
  font-size: 12px !important;
}

@media only screen and (min-width: 768px) {
  .asd__change-month-button > button {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .asd__action-buttons {
    display: none;
  }
}

.asd__action-buttons button {
  font-size: 12px;
  padding: 10px 0;
  color: #a8a8a8;
}

.asd__action-buttons button:nth-child(2) {
  color: white !important;
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 10px 30px;
}

.asd__day--in-range,
.asd__day--selected {
  button {
    text-decoration: none !important;
  }
}

.vue-slider-tooltip,
.initial-distance {
  font-weight: lighter;
  font-size: 16px !important;
}


.active {
  color: white;
  background-color: $primary-black;
}

.checkbox input[type="checkbox"] {
  display: none;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid;
  top: 4px;
  left: -50px;
}

.checkbox label::after {
  content: "";
  display: inline-block;
  height: 6px;
  width: 9px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(-45deg);
  left: -46px;
  top: 7px;
}

.checkbox label {
  position: relative;
  left: 52px;
  font-size: 15px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
}

.checkbox-icon {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin: 0 8px;
  position: absolute;
  left: -40px;
}

.checkbox input[type="checkbox"]:checked + label::before {
  background-color: $secondary-black;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
  content: none;
}

/*Unhide the checkmark on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}

.dropdown-wrapper {
  position: relative;
}

.btn-icon {
  position: absolute;
  left: 20px;
  top: 12.5px;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.dropdown-title {
  font-weight: bold;
  font-size: 18px;
  color: $secondary-black;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.dropdown-menu {
  left: -45px !important;
  min-width: 190px !important;
}

//Notification
.vue-notification {
  margin-top: 5px !important;
  font-size: 14px !important;
}

.eco-indicator {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #28a745;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

.eco-indicator i {
  margin-right: 5px;
}

</style>
